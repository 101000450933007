$success: #70bf45 !default;

@import '../../node_modules/@stronghold/bootstrap/scss/bootstrap';
@import '../../node_modules/react-phone-input-2/lib/style.css';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';

$break-large: 1200px;
$break-medium: 641px;
$break-small: 320px;

html,
body,
#root {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    background-color: $gray-300;
}

// Button
.btn.btn-lg {
    .spinner-grow-sm {
        // Tweaks for having the spinner vertical aligned
        width: 1.4rem;
        height: 1.4rem;
        vertical-align: sub;
    }
}

// React date picker
.react-datepicker {
    border-color: $border-color !important;
}

.react-datepicker__header {
    border-bottom-color: $border-color !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: $primary !important;
}

.react-datepicker__triangle::before {
    border-bottom-color: $border-color !important;
}

.react-datepicker__year-dropdown--scrollable {
    border-color: $border-color !important;
}

.react-datepicker__year-option--selected_year {
    background-color: $primary !important;
    color: white;

    > span {
        display: none;
    }
}

.react-datepicker__month-dropdown {
    border-color: $border-color !important;
}

.react-datepicker__month-option--selected_month {
    background-color: $primary !important;
    color: white;

    > span {
        display: none;
    }
}

.react-datepicker-popper {
    z-index: 99 !important;
}

// Progress bar
.progress-bar-bottom {
    height: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

// Phone number
.react-tel-input {
    font-family: inherit !important;
    font-size: inherit !important;
    padding: 0 !important;

    > input {
        @extend .form-control;
        padding-left: 54px+24px !important;
        height: 100%;
    }

    > .flag-dropdown {
        @extend .rounded-left;
        border-width: 0 !important;
        background-color: $gray-200 !important;

        > .selected-flag {
            padding: 0 1rem !important;
            width: 55px !important;
        }

        > .selected-flag:hover {
            background-color: $white !important;
        }
    }
}

.input-group-prepend .btn {
    z-index: 1 !important;
}

.PhoneInput {
    @extend .form-control;
    @extend .px-0;
    @extend .pl-1;
    @extend .d-flex;

    .PhoneInputCountry {
        @extend .px-2;
        @extend .m-0;

        .PhoneInputCountryIcon {
            @extend .shadow-none;
        }

        .PhoneInputCountrySelectArrow {
            border-bottom: 1px solid;
            border-right: 1px solid;
        }

        img {
            vertical-align: top;
        }
    }

    .PhoneInputInput {
        @extend .form-control;
        @extend .px-2;

        border-bottom-width: 0;
        margin-bottom: 1px;
    }

    &.is-invalid {
        > div {
            border-bottom-color: $danger;
        }

        > * {
            @extend .is-invalid;
        }
    }
}

// Masked Input
.letter-spacing::placeholder {
    letter-spacing: 0.3rem !important;
}

.letter-spacing {
    letter-spacing: 0.3rem !important;
}

.form-control.is-valid {
    background-image: none;
}

// Check out

div.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 99;
}

.slider {
    height: 100%;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slider.opened {
    /* visible */
    -webkit-transform: translate(0, 0%);
    -moz-transform: translate(0, 0%);
    -ms-transform: translate(0, 0%);
    transform: translate(0, 0%);
}

.slider.closed {
    /* completely offscreen */
    -webkit-transform: translate(0, -100%);
    -moz-transform: translate(0, -100%);
    -ms-transform: translate(0, -100%);
    transform: translate(0, -100%);
}

// List group
.list-group-item.active {
    background-color: $gray-300;
    border-color: $gray-300;
    color: $dark;
}

// tip buttons
$tip-element-width: 100px;
$tip-element-active-width: 110px;
$tip-element-margin: ($tip-element-active-width - $tip-element-width) / 2;
$tip-element-active-margin: 0px;
.btn-tip {
    @extend .rounded-circle, .p-2, .font-weight-normal;
    width: $tip-element-width;
    height: $tip-element-width;
    margin: $tip-element-margin;
    font-size: 18px;
    transition: width 0.25s, height 0.25s, margin 0.25s;

    &.active {
        width: $tip-element-active-width;
        height: $tip-element-active-width;
        margin: $tip-element-active-margin;
    }

    .dollar-amount {
        font-size: 12px;
    }
}

.form-control-tip {
    @extend .border, .rounded-circle, .p-0, .text-center, .btn-primary;
    width: $tip-element-width;
    height: $tip-element-width;
    font-size: 20px;
    transition: width 0.25s, height 0.25s;
    outline: none !important;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;

    &.active {
        width: $tip-element-active-width;
        height: $tip-element-active-width;
    }

    &:focus {
        outline: none !important;
        color: transparent !important;
        text-shadow: 0 0 0 white !important;
    }
}

.text-alert-stronghold {
    background-color: #bdcfec;
}

.btn-custom-tip {
    color: black;
    background-color: white;
}

.contact-method {
    margin-right: 5px;
}